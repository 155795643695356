import React from "react";
import open1 from "../../assets/img/open.png";
function HomeOpen() {
    return(
        <div className=" w-full relative after:h-[85%] sm:after:h-[80%] after:w-[100%] after:absolute after:bottom-0 after:left-0 after:bg-[#0E264F]">
            <div className="max-w-screen-xl mx-auto px-8 relative  sm:px-14 lg:px-24 xl:px-0 mt-10 flex flex-col md:flex-row">
                <div className="md:w-1/2 relative  z-50">
                    <img src={open1} />
                </div>
                <div className="max-md:mt-10 md:w-1/2  flex flex-col justify-center relative z-50 pl-8 sm:space-y-4">
                    <p className="text-[#3660d9] font-bold font-pop">Book Dentail Appointment</p>
                    <h1 className="text-[20px] sm:text-[30px]  lg:text-[40px]  md:leading-[70px] xl:text-[50px] text-white font-bold font-pop">We Are open And Welcoming Patients</h1>
                    <div className="max-md:mb-4">
                            <button className="btn2  max-sm:mt-3">Book Appointment</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeOpen