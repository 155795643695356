import React from "react";
import Slider from "react-slick";
import team1 from '../../assets/img/team1.png';
import team2 from '../../assets/img/team2.png';
import team3 from '../../assets/img/team3.png';
import team4 from '../../assets/img/team4.png';
import {FaFacebookF} from 'react-icons/fa';
import {FaInstagram} from 'react-icons/fa';
import {FaTwitter} from 'react-icons/fa';
function HomeStaff() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 870,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 660,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
         
        ]
    };
    return (
        <>
            <div className="max-w-screen-xl mx-auto py-6 px-0 sm:px-14 lg:px-24 xl:px-0 mt-10" id="team">
                <div className="max-w-screen-xl mx-auto py-6 px-14 lg:px-24 xl:px-0 mt-10">
                    <p className="text-[18px] text-center font-bold font-press-start text-[#3660d9]">OUR DOCTOR</p>
                    <h1 className="text-center font-bold font-pop text-[16px]  sm:text-[60px] text-[#00173c]">Best Expert Dentist</h1>
                </div>
                <Slider {...settings} className={''}>
                    <div className="p-4">
                        <div className="border p-[30px] border-[rgb(215, 215, 215)]">
                            <div>
                                <img src={team1} />
                            </div>
                            <div className="mt-3">
                                <h1 className="py-4 transition-all duration-300 cursor-pointer text-[24px] text-center text-[#00173c] hover:text-[#3660d9] font-bold font-pop">Vincent Cooper</h1>
                                <p className="text-[#3660d9] text-center font-pop">Dentist</p>
                            </div>
                            <div className="mt-4">
                                <ul className="flex justify-center space-x-4">
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaFacebookF />
                                    </li>
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaTwitter />
                                    </li>
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaInstagram />    
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="p-4">
                        <div className="border p-[30px] border-[rgb(215, 215, 215)]">
                            <div>
                                <img src={team2} />
                            </div>
                            <div className="mt-3">
                                <h1 className="py-4 transition-all duration-300 cursor-pointer text-[24px] text-center text-[#00173c] hover:text-[#3660d9] font-bold font-pop">Danielle Bryant</h1>
                                <p className="text-[#3660d9] text-center font-pop">Dentist</p>
                            </div>
                            <div className="mt-4">
                                <ul className="flex justify-center space-x-4">
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaFacebookF />
                                    </li>
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaTwitter />
                                    </li>
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaInstagram />    
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="p-4">
                        <div className="border p-[30px] border-[rgb(215, 215, 215)]">
                            <div>
                                <img src={team3} />
                            </div>
                            <div className="mt-3">
                                <h1 className="py-4 transition-all duration-300 cursor-pointer text-[24px] text-center text-[#00173c] hover:text-[#3660d9] font-bold font-pop">Danielle Bryant</h1>
                                <p className="text-[#3660d9] text-center font-pop">Dentist</p>
                            </div>
                            <div className="mt-4">
                                <ul className="flex justify-center space-x-4">
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaFacebookF />
                                    </li>
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaTwitter />
                                    </li>
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaInstagram />    
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="p-4">
                        <div className="border p-[30px] border-[rgb(215, 215, 215)]">
                            <div>
                                <img src={team4} />
                            </div>
                            <div className="mt-3">
                                <h1 className="py-4 transition-all duration-300 cursor-pointer text-[24px] text-center text-[#00173c] hover:text-[#3660d9] font-bold font-pop">Danielle Bryant</h1>
                                <p className="text-[#3660d9] text-center font-pop">Dentist</p>
                            </div>
                            <div className="mt-4">
                                <ul className="flex justify-center space-x-4">
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaFacebookF />
                                    </li>
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaTwitter />
                                    </li>
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaInstagram />    
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="p-4">
                        <div className="border p-[30px] border-[rgb(215, 215, 215)]">
                            <div>
                                <img src={team1} />
                            </div>
                            <div className="mt-3">
                                <h1 className="py-4 transition-all duration-300 cursor-pointer text-[24px] text-center text-[#00173c] hover:text-[#3660d9] font-bold font-pop">Vincent Cooper</h1>
                                <p className="text-[#3660d9] text-center font-pop">Dentist</p>
                            </div>
                            <div className="mt-4">
                                <ul className="flex justify-center space-x-4">
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaFacebookF />
                                    </li>
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaTwitter />
                                    </li>
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaInstagram />    
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="p-4">
                        <div className="border p-[30px] border-[rgb(215, 215, 215)]">
                            <div>
                                <img src={team2} />
                            </div>
                            <div className="mt-3">
                                <h1 className="py-4 transition-all duration-300 cursor-pointer text-[24px] text-center text-[#00173c] hover:text-[#3660d9] font-bold font-pop">Danielle Bryant</h1>
                                <p className="text-[#3660d9] text-center font-pop">Dentist</p>
                            </div>
                            <div className="mt-4">
                                <ul className="flex justify-center space-x-4">
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaFacebookF />
                                    </li>
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaTwitter />
                                    </li>
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaInstagram />    
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="p-4">
                        <div className="border p-[30px] border-[rgb(215, 215, 215)]">
                            <div>
                                <img src={team3} />
                            </div>
                            <div className="mt-3">
                                <h1 className="py-4 transition-all duration-300 cursor-pointer text-[24px] text-center text-[#00173c] hover:text-[#3660d9] font-bold font-pop">Danielle Bryant</h1>
                                <p className="text-[#3660d9] text-center font-pop">Dentist</p>
                            </div>
                            <div className="mt-4">
                                <ul className="flex justify-center space-x-4">
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaFacebookF />
                                    </li>
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaTwitter />
                                    </li>
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaInstagram />    
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="p-4">
                        <div className="border p-[30px] border-[rgb(215, 215, 215)]">
                            <div>
                                <img src={team4} />
                            </div>
                            <div className="mt-3">
                                <h1 className="py-4 transition-all duration-300 cursor-pointer text-[24px] text-center text-[#00173c] hover:text-[#3660d9] font-bold font-pop">Danielle Bryant</h1>
                                <p className="text-[#3660d9] text-center font-pop">Dentist</p>
                            </div>
                            <div className="mt-4">
                                <ul className="flex justify-center space-x-4">
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaFacebookF />
                                    </li>
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaTwitter />
                                    </li>
                                    <li className="w-12 h-12 text-[16px] flex justify-center items-center transition-all duration-300 rounded-full bg-[#d7dff7] text-[#3660d9] hover:text-white cursor-pointer hover:bg-[#3660d9]">
                                        <FaInstagram />    
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                  
                </Slider>
            </div>
        </>
    )
}

export default HomeStaff