import React from "react";
import PImg1 from "../../assets/img/price1.png";
import PImg2 from "../../assets/img/price2.png";
import PImg3 from "../../assets/img/price3.png";
import CHImg from "../../assets/img/check.png";
function HomePrice() {
    return (
        <>
            <div className="max-w-screen-xl mx-auto py-6 px-14 lg:px-24 xl:px-0 mt-10" id="pricing">
                <p className="text-[18px] text-center font-bold font-press-start text-[#3660d9]">Testimonial</p>
                <h1 className="text-center font-bold font-pop text-[16px]  sm:text-[60px] text-[#00173c]">Our Pricing</h1>
            </div>
            <div className="max-w-screen-xl mx-auto py-6 sm:px-14 lg:px-24 xl:px-0 mt-10 flex flex-col lg:flex-row max-lg:space-y-8 lg:space-x-6">
                <div className="max-w-lg max-lg:mx-auto py-[10px] sm:p-[30px]  lg:w-1/3 bg-[white] rounded-[4px]  xl:p-[50px] shadow-[0px_20px_60px_0px_rgba(0,0,0,.08)]">
                    <div>
                        <div className="flex justify-center">
                            <img src={PImg1} className={'w-12 h-12'} />
                        </div>
                        <div>
                            <h1 className="text-[#00173c] font-bold font-pop text-[1.4rem] lg:text-[1.75rem] text-center mt-2">Basic Plan</h1>
                            <p className="text-[#3660d9] font-bold font-pop text-[40px] lg:text-[50px] text-center">$49<span className="text-[#00173c] text-[15px]"> / Monthly</span></p>
                        </div>
                        <hr />
                    </div>
                    <div className="">
                        <ul className="px-4 lg:px-6 py-8 text-[15px] text-[#777] font-pop">
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                        </ul>
                        <div className="flex justify-center">
                            <button className="btn2 max-sm:w-full max-sm:mt-3">CHOOSE PLAN</button>
                        </div>
                    </div>
                </div>
                <div className="max-w-lg max-lg:mx-auto py-[10px] sm:p-[30px]  lg:w-1/3 bg-[white] rounded-[4px]  xl:p-[50px] shadow-[0px_20px_60px_0px_rgba(0,0,0,.08)]">
                    <div>
                        <div className="flex justify-center">
                            <img src={PImg2} className={'w-12 h-12'} />
                        </div>
                        <div>
                            <h1 className="text-[#00173c] font-bold font-pop text-[1.4rem] lg:text-[1.75rem] text-center mt-2">Standard  Plan</h1>
                            <p className="text-[#3660d9] font-bold font-pop text-[40px] lg:text-[50px] text-center">$99<span className="text-[#00173c] text-[15px]"> / Monthly</span></p>
                        </div>
                        <hr />
                    </div>
                    <div className="">
                        <ul className="px-4 lg:px-6 py-8 text-[15px] text-[#777] font-pop">
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                        </ul>
                        <div className="flex justify-center">
                            <button className="btn2 max-sm:w-full max-sm:mt-3">CHOOSE PLAN</button>
                        </div>
                    </div>
                </div>
                <div className="max-w-lg max-lg:mx-auto py-[10px] sm:p-[30px]  lg:w-1/3 bg-[white] rounded-[4px]  xl:p-[50px] shadow-[0px_20px_60px_0px_rgba(0,0,0,.08)]">
                    <div>
                        <div className="flex justify-center">
                            <img src={PImg3} className={'w-12 h-12'} />
                        </div>
                        <div>
                            <h1 className="text-[#00173c] font-bold font-pop text-[1.4rem] lg:text-[1.75rem] text-center mt-2">Unlimited  Plan</h1>
                            <p className="text-[#3660d9] font-bold font-pop text-[40px] lg:text-[50px] text-center">$149<span className="text-[#00173c] text-[15px]"> / Monthly</span></p>
                        </div>
                        <hr />
                    </div>
                    <div className="">
                        <ul className="px-4 lg:px-6 py-8 text-[15px] text-[#777] font-pop">
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                        </ul>
                        <div className="flex justify-center">
                            <button className="btn2 max-sm:w-full max-sm:mt-3">CHOOSE PLAN</button>
                        </div>
                    </div>
                </div>
                {/* <div className="max-lg:mx-auto py-[10px] sm:p-[30px]  lg:w-1/3 bg-[white] rounded-[4px]  xl:p-[50px] shadow-[0px_20px_60px_0px_rgba(0,0,0,.08)]">
                    <div>
                        <div className="flex justify-center">
                            <img src={PImg1} className={'w-12 h-12'} />
                        </div>
                        <div>
                            <h1 className="text-[#00173c] font-bold font-pop text-[1.4rem] lg:text-[1.75rem] text-center mt-2">Standard  Plan</h1>
                            <p className="text-[#3660d9] font-bold font-pop text-[40px] lg:text-[50px] text-center">$99<span className="text-[#00173c] text-[15px]"> / Monthly</span></p>
                        </div>
                        <hr />
                    </div>
                    <div className="">
                        <ul className="px-4 lg:px-6 py-8 text-[15px] text-[#777] font-pop">
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                        </ul>
                        <div className="flex justify-center">
                            <button className="btn2 max-sm:w-full max-sm:mt-3">CHOOSE PLAN</button>
                        </div>
                    </div>
                </div>
                <div className="max-lg:mx-auto py-[10px] sm:p-[30px]  lg:w-1/3 bg-[white] rounded-[4px]  xl:p-[50px] shadow-[0px_20px_60px_0px_rgba(0,0,0,.08)]">
                    <div>
                        <div className="flex justify-center">
                            <img src={PImg1} className={'w-12 h-12'} />
                        </div>
                        <div>
                            <h1 className="text-[#00173c] font-bold font-pop text-[1.4rem] lg:text-[1.75rem] text-center mt-2">Unlimited  Plan</h1>
                            <p className="text-[#3660d9] font-bold font-pop text-[40px] lg:text-[50px] text-center">$149<span className="text-[#00173c] text-[15px]"> / Monthly</span></p>
                        </div>
                        <hr />
                    </div>
                    <div className="">
                        <ul className="px-4 lg:px-6 py-8 text-[15px] text-[#777] font-pop">
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                            <li className="flex py-2">
                                <img src={CHImg} className={'w-6 h-6 mr-2 lg:mr-4'} /> Aliquam quis justo at lorem
                            </li>
                        </ul>
                        <div className="flex justify-center">
                            <button className="btn2 max-sm:w-full max-sm:mt-3">CHOOSE PLAN</button>
                        </div>
                    </div>
                </div> */}
              
            </div>
        </>
    )
}

export default HomePrice