import React from "react"
import NEWLOGO from '../../assets/img/whiteLogo.png';
import ClIMG from "../../assets/img/clock.png";
import LIMG from '../../assets/img/location.png';
import TIMG from '../../assets/img/tel1.png';
import EIMG from '../../assets/img/envv.png';
import { Link } from "react-scroll";
function Footer() {
    return (
        <>
            <div className="py-16 bg-[#00173C]">
                <div className="max-w-screen-xl mx-auto py-6  sm:px-14 lg:px-24 xl:px-0 mt-10 flex flex-col lg:flex-row max-lg:space-y-8">
                    <div className="max-lg:mx-auto cursor-pointer w-[90%] lg:w-1/3">
                        <div>
                            <img src={NEWLOGO} className={'w-[208px] h-[43px]'} />
                        </div>
                        <div className="text-[#b2c0d8] text-[13px] mt-6">
                            Mauris non nisi semper, lacinia neque in, dapibus leo. Curabitur sagittis libero tincidunt tempor finibus. Mauris at dignissim ligula, nec tristique orci.Quisque vitae metus.
                        </div>
                        <div className="flex items-center space-x-6 mt-6">
                            <div className="rounded-full bg-[#0998E6] flex justify-center items-center h-8 w-8">
                                <img src={ClIMG} className={'h-4 w-4'} />
                            </div>
                            <div className="text-white font-pop text-[13px]">
                                <p>Monday - Saturday:</p>
                                <p>9:00am - 10:00Pm</p>
                            </div>
                        </div>
                    </div>
                    <div className="max-lg:mx-auto cursor-pointer w-[90%] lg:w-1/3  flex flex-col sm:flex-row max-sm:space-y-3">
                        <div className="w-full sm:w-1/2">
                            <h1 className="text-white text-[24px] font-bold font-pop">Other Links</h1>
                            <ul>

                                <li className="text-[#3660d9] text-[18px] group"> +
                                    <Link to="about" spy={true} smooth={true} offset={0} duration={500} className="text-[#b2c0d8] text-[15px] transition-all duration-300 ml-2 font-pop group-hover:text-[#fff]">About</Link>
                                </li>
                                <li className="text-[#3660d9] text-[18px] group"> +
                                    <Link to="service" spy={true} smooth={true} offset={0} duration={500} className="text-[#b2c0d8] text-[15px] transition-all duration-300 ml-2 font-pop group-hover:text-[#fff]">Services</Link>
                                </li>
                                <li className="text-[#3660d9] text-[18px] group"> +
                                    <Link to="opinion" spy={true} smooth={true} offset={0} duration={500} className="text-[#b2c0d8] text-[15px] transition-all duration-300 ml-2 font-pop group-hover:text-[#fff]">Opinions</Link>
                                </li>
                                <li className="text-[#3660d9] text-[18px] group"> +
                                    <Link to="pricing" spy={true} smooth={true} offset={0} duration={500} className="text-[#b2c0d8] text-[15px] transition-all duration-300 ml-2 font-pop group-hover:text-[#fff]">Pricing</Link>
                                </li>
                                <li className="text-[#3660d9] text-[18px] group"> +
                                    <Link to="team" spy={true} smooth={true} offset={0} duration={500} className="text-[#b2c0d8] text-[15px] transition-all duration-300 ml-2 font-pop group-hover:text-[#fff]">Our Team</Link>
                                </li>
                                <li className="text-[#3660d9] text-[18px] group"> +
                                    <Link to="blog" spy={true} smooth={true} offset={0} duration={500} className="text-[#b2c0d8] text-[15px] transition-all duration-300 ml-2 font-pop group-hover:text-[#fff]">Blog</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="w-full sm:w-1/2">
                            <h1 className="text-white text-[24px] font-bold font-pop">Our Services</h1>
                            <ul>
                                <li className="text-[#3660d9] text-[18px] group"> +
                                    <Link to="" spy={true} smooth={true} offset={0} duration={500} className="text-[#b2c0d8] text-[15px] transition-all duration-300 ml-2 font-pop group-hover:text-[#fff]">Root Canal</Link>
                                </li>
                                <li className="text-[#3660d9] text-[18px] group"> +
                                    <Link to="" spy={true} smooth={true} offset={0} duration={500} className="text-[#b2c0d8] text-[15px] transition-all duration-300 ml-2 font-pop group-hover:text-[#fff]">Alignment Teeth</Link>
                                </li>
                                <li className="text-[#3660d9] text-[18px] group"> +
                                    <Link to="" spy={true} smooth={true} offset={0} duration={500} className="text-[#b2c0d8] text-[15px] transition-all duration-300 ml-2 font-pop group-hover:text-[#fff]">Cosmetic Teeth</Link>
                                </li>
                                <li className="text-[#3660d9] text-[18px] group"> +
                                    <Link to="" spy={true} smooth={true} offset={0} duration={500} className="text-[#b2c0d8] text-[15px] transition-all duration-300 ml-2 font-pop group-hover:text-[#fff]">Oral Hygiene</Link>
                                </li>
                                <li className="text-[#3660d9] text-[18px] group"> +
                                    <Link to="" spy={true} smooth={true} offset={0} duration={500} className="text-[#b2c0d8] text-[15px] transition-all duration-300 ml-2 font-pop group-hover:text-[#fff]">Live Advisory</Link>
                                </li>
                                <li className="text-[#3660d9] text-[18px] group"> +
                                    <Link to="" spy={true} smooth={true} offset={0} duration={500} className="text-[#b2c0d8] text-[15px] transition-all duration-300 ml-2 font-pop group-hover:text-[#fff]">Cavity Inspection</Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className="max-lg:mx-auto cursor-pointer w-[90%] lg:w-1/3">
                        <div className="flex items-center space-x-6 mt-6">
                            <div className="rounded-full bg-[#0998E6] flex justify-center items-center h-8 w-8">
                                <img src={LIMG} className={'h-4 w-4'} />
                            </div>
                            <div className="text-white font-pop text-[13px]">
                                <p>1247/Plot No. 39, 15th Phase:</p>
                                <p>LHB Colony, Kanpur</p>
                            </div>
                        </div>
                        <div className="flex items-center space-x-6 mt-6">
                            <div className="rounded-full bg-[#0998E6] flex justify-center items-center h-8 w-8">
                                <img src={TIMG} className={'h-4 w-4'} />
                            </div>
                            <div className="text-white font-pop text-[13px]">
                                <p>1800-121-3637</p>
                                <p>+91-7052-101-786</p>
                            </div>
                        </div>
                        <div className="flex items-center space-x-6 mt-6">
                            <div className="rounded-full bg-[#0998E6] flex justify-center items-center h-8 w-8">
                                <img src={EIMG} className={'h-4 w-4'} />
                            </div>
                            <div className="text-white font-pop text-[13px]">
                                <p>info@example.com</p>
                                <p>help@example.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer