import React from "react";
import Img1 from "../../assets/img/about.png";
function HomeAbout() {
    return (
        <>
            <div className="max-w-screen-xl mx-auto mt-8 py-6 px-4 sm:px-14 lg:px-24 xl:px-0 flex flex-col md:flex-row" id="about">
                <div className="w-full md:w-1/2 flex items-center max-md:justify-center">
                    <img src={Img1} />
                </div>
                <div className="w-full md:w-1/2 sm:px-6 xl:p-0">
                    <p className="text-[#3660d9] font-bold font-pop text-[18px] mt-5"> ABOUT US</p>
                    <h1 className="text-[#00173c] font-bold font-pop text-[30px] lg:text-[40px] xl:text-[70px] leading-[1.2]">
                        We Care For Your Dental Health
                    </h1>
                    <p className="text-[15px] text-[#777] mt-3">
                        Aliquam ac sem et diam iaculis efficitur. Morbi in enim odio. Nullam quis volutpat est, sed dapibus sapien. Cras condimentum eu velit id tempor. Curabitur purus sapien, cursus sed nisl tristique, commodo vehicula arcu.
                    </p>
                    <p className="text-[15px] text-[#777] mt-3">
                        Aliquam ac sem et diam iaculis efficitur. Morbi in enim odio. Nullam quis volutpat est, sed dapibus sapien. Cras condimentum eu velit id tempor. Curabitur purus sapien, cursus sed nisl tristique, commodo vehicula arcu.
                    </p>
                    <div className="mt-10">
                        <button className="btn max-sm:w-full max-sm:mt-3">GET CALL BACK</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeAbout