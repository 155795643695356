import React from "react";
import EnvPng from '../../assets/img/env.png';
import TelPng from '../../assets/img/tel.png';
import FPng from '../../assets/img/f.png';
import YPng from '../../assets/img/youtube.png';
import InstaPng from '../../assets/img/insta.png';
import FB from '../../assets/img/FB.png';
import IB from '../../assets/img/IB.png';
import YB from '../../assets/img/YB.png';
import Logo from '../../assets/img/logo.png';
import { Link } from "react-scroll";
function Header() {
    const [open, setOpen] = React.useState(false);
    const [flyer, setFlyer] = React.useState(false);
    const [flyerTwo, setFlyerTwo] = React.useState(false);
    return (
        <>
            <div className="bg-[#0e264f] py-6 max-md:hidden" id="home">
                <div className="max-w-screen-xl flex justify-between max-xl:px-24 mx-auto">
                    <div>
                        <ul className="flex space-x-6 font-press-start-lato">
                            <li className="text-white text-sm flex items-center">
                                <img src={EnvPng} className={'w-6 h-6'} />
                                info@example.com
                            </li>
                            <li className="text-white text-sm flex items-center">
                                <img src={TelPng} className={'w-6 h-6'} />
                                +374 96 00 00 00
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className="flex space-x-3">
                            <li>
                                <a href="" className="group transition-all duration-700">
                                    <img src={FPng} className={'h-6 w-6 transition-all duration-700 group-hover:hidden'} />
                                    <img src={FB} className={'h-6 w-6 hidden transition-all duration-700 group-hover:block'} />
                                </a>
                            </li>
                            <li>
                                <a href="" className="group transition-all duration-700">
                                    <img src={InstaPng} className={'h-6 w-6 transition-all duration-700 group-hover:hidden'} />
                                    <img src={IB} className={'h-6 w-6 hidden transition-all duration-700 group-hover:block'} />
                                </a>
                            </li>
                            <li>
                                <a href="" className="group transition-all duration-700">
                                    <img src={YPng} className={'h-6 w-6 transition-all duration-700 group-hover:hidden'} />
                                    <img src={YB} className={'h-6 w-6 hidden transition-all duration-700 group-hover:block'} />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="max-w-screen-xl Menu_Content mx-auto py-6 px-4 sm:px-14 lg:px-24 xl:px-0">
                <div className="flex justify-between items-center  py-2 md:justify-start md:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <Link to="/">
                            <img src={Logo} className={'w-44'} />
                        </Link>
                    </div>
                    <div className=" bg-white md:hidden">
                        <button
                            type="button"
                            className="rounded-md  inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                            onClick={() => setOpen(!open)}
                        >

                            {/* <span className="sr-only">Open menu</span> */}
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                        </button>
                    </div>
                    <nav className="hidden md:flex space-x-10 relative z-50">


                    <Link to="home" spy={true} smooth={true} offset={0} duration={500} className="header-link">Home</Link>
                            <Link to="about" spy={true} smooth={true} offset={0} duration={500} className="header-link">About</Link>
                            <Link to="service" spy={true} smooth={true} offset={0} duration={500} className="header-link">Service</Link>
                            <Link to="opinion" spy={true} smooth={true} offset={0} duration={500} className="header-link">Opinion</Link>
                            <Link to="pricing" spy={true} smooth={true} offset={0} duration={500} className="header-link">Pricing</Link>
                            <Link to="team" spy={true} smooth={true} offset={0} duration={500} className="header-link">Our Team</Link>
                            <Link to="blog" spy={true} smooth={true} offset={0} duration={500} className="header-link">Blog</Link>


                    </nav>

                </div>
            </div>


            <div
                className={
                    open
                        ? "opacity-100 scale-100 transition ease-out duration-200 absolute top-0 inset-x-0 p-2  transform origin-top-right md:hidden"
                        : "opacity-0 scale-95 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                }
            >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                    <div className="pt-5 pb-6 px-5">
                        <div className="flex items-center justify-between">
                            <div>
                                <Link to={'/'}>
                                    <img
                                        className="h-8 w-auto"
                                        src={Logo}
                                        alt="Workflow"
                                    />
                                </Link>
                            </div>
                            <div className="-mr-2">
                                <button
                                    type="button"
                                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                    onClick={() => setOpen(!open)}
                                >
                                    <span className="sr-only">Close menu</span>
                                    <svg
                                        className="h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className="py-6 px-5 space-y-6">
                        <div className="grid grid-cols-1 gap-y-4 gap-x-8">

                            <Link to="home" spy={true} smooth={true} offset={0} duration={500} className="header-link">Home</Link>
                            <Link to="about" spy={true} smooth={true} offset={0} duration={500} className="header-link">About</Link>
                            <Link to="service" spy={true} smooth={true} offset={0} duration={500} className="header-link">Service</Link>
                            <Link to="opinion" spy={true} smooth={true} offset={0} duration={500} className="header-link">Opinion</Link>
                            <Link to="pricing" spy={true} smooth={true} offset={0} duration={500} className="header-link">Pricing</Link>
                            <Link to="team" spy={true} smooth={true} offset={0} duration={500} className="header-link">Our Team</Link>
                            <Link to="blog" spy={true} smooth={true} offset={0} duration={500} className="header-link">Blog</Link>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default Header