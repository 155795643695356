import { Route, Routes } from "react-router-dom";
import Home from "./Pages/home";
import Footer from "./Pages/Layouts/footer";
import Header from "./Pages/Layouts/header";


function App() {
  return (
    < >
      <Header />
            <Routes>
                <Route path="/" element={<Home />} />
            </Routes>
            <Footer />
    </>
  );
}

export default App;
