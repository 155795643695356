import React from "react";
import TImage from '../../assets/img/services-img-details2.ae291c66c5cb4fc577a2.png';
import S1IMG from '../../assets/img/s1.png';
import S2IMG from '../../assets/img/s2.png';
import S3IMG from '../../assets/img/s3.png';
import S4IMG from '../../assets/img/s4.png';
import S5IMG from '../../assets/img/s5.png';
import S6IMG from '../../assets/img/s6.png';
function HomeService() {
    return (
        <>
            <div className="max-w-screen-xl Menu_Content mx-auto py-6 px-14 lg:px-24 xl:px-0 mt-10" id="service">
                <p className="text-[18px] text-center font-bold font-press-start text-[#3660d9]">OUR SERVICES</p>
                <h1 className="text-center font-bold font-pop max-sm:text-[20px] sm:text-[60px] text-[#00173c]">What We Provide</h1>
            </div>
            <div className="max-w-screen-xl Menu_Content mx-auto py-6 px-4 sm:px-14 lg:px-24 xl:px-0  flex max-md:flex-col">
                <div className="w-full sm:w-1/2  lg:w-1/3 flex flex-col space-y-3">
                    <div className="border border-[rgb(215, 215, 215)] flex p-2 sm:p-[30px]">
                        <div className="text-right w-4/5">
                            <h1 className="text-[#00173c] font-bold font-pop sm:text-[22px]">Root Canal</h1>
                            <p className="text-[#777] font-pop text-[12px] sm:text-[14px]">Aenean eleifend turpis tellus, nec laoreet metus elementum ac.
                            </p>
                        </div>
                        <div className="w-1/5 flex justify-center items-center">
                            <img src={S1IMG} className={'w-[32px]  sm:w-[48px] h-[32px]  sm:h-[48px]'} />
                        </div>
                    </div>
                    <div className="border border-[rgb(215, 215, 215)] flex p-2 sm:p-[30px]">
                        <div className="text-right w-4/5">
                            <h1 className="text-[#00173c] font-bold font-pop sm:text-[22px]">Alignment Teeth</h1>
                            <p className="text-[#777] font-pop text-[12px] sm:text-[14px]">Aenean eleifend turpis tellus, nec laoreet metus elementum ac.
                            </p>
                        </div>
                        <div className="w-1/5 flex justify-center items-center">
                            <img src={S2IMG} className={'w-[32px]  sm:w-[48px] h-[32px]  sm:h-[48px]'} />
                        </div>
                    </div>
                    <div className="border border-[rgb(215, 215, 215)] flex p-2 sm:p-[30px]">
                        <div className="text-right w-4/5">
                            <h1 className="text-[#00173c] font-bold font-pop sm:text-[22px]">Cosmetic Teeth</h1>
                            <p className="text-[#777] font-pop text-[12px] sm:text-[14px]">Aenean eleifend turpis tellus, nec laoreet metus elementum ac.
                            </p>
                        </div>
                        <div className="w-1/5 flex justify-center items-center">
                            <img src={S3IMG} className={'w-[32px]  sm:w-[48px] h-[32px]  sm:h-[48px]'} />
                        </div>
                    </div>
                </div>
                <div className="w-1/3 max-lg:hidden flex justify-center">
                    <img src={TImage} className={''} />
                </div>
                <div className="w-full sm:w-1/2  lg:w-1/3 flex flex-col space-y-3 max-sm:mt-5">
                    <div className="border border-[rgb(215, 215, 215)] flex p-2 sm:p-[30px]">
                        <div className="w-1/5 flex justify-center items-center">
                            <img src={S4IMG} className={'w-[32px]  sm:w-[48px] h-[32px]  sm:h-[48px]'} />
                        </div>
                        <div className="w-4/5">
                            <h1 className="text-[#00173c] font-bold font-pop sm:text-[22px]">
                                Oral Hygiene</h1>
                            <p className="text-[#777] font-pop text-[12px] sm:text-[14px]">Aenean eleifend turpis tellus, nec laoreet metus elementum ac.
                            </p>
                        </div>

                    </div>
                    <div className="border border-[rgb(215, 215, 215)] flex p-2 sm:p-[30px]">
                        <div className="w-1/5 flex justify-center items-center">
                            <img src={S5IMG} className={'w-[32px]  sm:w-[48px] h-[32px]  sm:h-[48px]'} />
                        </div>
                        <div className="w-4/5">
                            <h1 className="text-[#00173c] font-bold font-pop sm:text-[22px]">
                            Live Advisory</h1>
                            <p className="text-[#777] font-pop text-[12px] sm:text-[14px]">Aenean eleifend turpis tellus, nec laoreet metus elementum ac.
                            </p>
                        </div>

                    </div>
                    <div className="border border-[rgb(215, 215, 215)] flex p-2 sm:p-[30px]">
                        <div className="w-1/5 flex justify-center items-center">
                            <img src={S6IMG} className={'w-[32px]  sm:w-[48px] h-[32px]  sm:h-[48px]'} />
                        </div>
                        <div className="w-4/5">
                            <h1 className="text-[#00173c] font-bold font-pop sm:text-[22px]">
                            Cavity Inspection</h1>
                            <p className="text-[#777] font-pop text-[12px] sm:text-[14px]">Aenean eleifend turpis tellus, nec laoreet metus elementum ac.
                            </p>
                        </div>

                    </div>
                   
                </div>
            </div>
        </>
    )
}

export default HomeService