import React from "react"
import HomeAbout from "./Components/homeAbout"
import HomeAccardion from "./Components/homeAccardion"
import HomeBlog from "./Components/homeBlog"
import HomeFirst from "./Components/homeFirst"
import HomeOpen from "./Components/homeOpen"
import HomeOpinion from "./Components/homeOpinion"
import HomePrice from "./Components/homePrice"
import HomeService from "./Components/homeService"
import HomeStaff from "./Components/homeStaff"

function Home() {
    return (
        <>
            
            <HomeFirst />
            <HomeService />
            <HomeAbout />
            <HomeOpinion />
            <HomePrice />
            <HomeAccardion />
            <HomeStaff />
            <HomeOpen />
            <HomeBlog />
        </>
    )
}
export default Home