import React from "react";
import Dimage from '../../assets/img/slider-img.682db25416c41f1aded8.png';
function HomeFirst() {
    return (
        <>
            <div className="max-w-screen-xl Menu_Content mx-auto py-6 px-4 sm:px-14 lg:px-24 xl:px-0  flex max-md:flex-col" >
                <div className="w-full md:w-1/2 flex flex-col justify-center">
                    <p className="text-[#3660d9]  sm:text-[22px] font-bold font-press-start">WELCOME TO DECARE</p>
                    <h1 className="max-sm:mt-3 font-bold font-press-start text-[24px] sm:text-[36px] md:text-[50px] lg:text-[60px] xl:text-[90px] leading-[30px] sm:leading-[50px] lg:leading-[80px] xl:leading-[100px] text-[#0e264f]">
                        We Are Best Dental Service
                    </h1>
                    <p className=" max-sm:mt-3 font-bold font-press-start-lato text-[#777] text-[14px] sm:text-[16px] ">
                        Donec vitae libero non enim placerat eleifend aliquam erat volutpat. Curabitur diam ex, dapibus purus sapien, cursus sed nisl tristique, commodo gravida lectus non.
                    </p>
                    <div className="bg-white rounded-[4px] p-[15px] shadow-[0px_20px_60px_0px_rgba(0,0,0,.08)] mt-10 flex flex-col sm:flex-row items-center sm:space-x-2">
                        <input placeholder="Your Email Address..." className="w-full sm:w-[70%] h-[4.4rem] border border-[#f8f8f8] bg-[#f8f8f8] px-[1rem] rounded-[4px]" />
                        <button className="btn max-sm:w-full max-sm:mt-3">GET CALL BACK</button>
                    </div>
                </div>
                <div className="w-full md:w-1/2 flex justify-center md:justify-end">
                    <div className="w-[80%]">
                        <img src={Dimage} className={''} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeFirst