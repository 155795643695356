import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OpImg1 from "../../assets/img/opinion1.png";
import Stars from "../../assets/img/stars.png";
import Qt from "../../assets/img/qt.png";
import '../../assets/js/script';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
function HomeOpinion() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        responsive: [
            {
              breakpoint: 920,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
          ]
    };
    return (
        <>
            <div className="bg-[#F6F8FC] py-0 sm:py-24 border" id="opinion">
                <div className="max-w-screen-xl mx-auto py-6 px-14 lg:px-24 xl:px-0 mt-10">
                    <p className="text-[18px] text-center font-bold font-press-start text-[#3660d9]">Testimonial</p>
                    <h1 className="text-center font-bold font-pop text-[16px]  sm:text-[60px] text-[#00173c]">What Our Clients Says</h1>
                </div>
                <div className="max-w-screen-xl mx-auto py-6 px-0 sm:px-14 lg:px-24 xl:px-0">
                    <Slider {...settings} className={''}>
                        <div className="p-4 py-12">
                            <div className="bg-white px-6 py-6 shadow-[1px_2px_60px_0px_rgba(0,0,0,.01)] rounded-[4px] relative after:w-24">
                                <div className="absolute -top-14 left-0">
                                    <img src={OpImg1} />
                                </div>
                                <div className="flex justify-between mt-6 items-end">
                                    <div>
                                        <img src={Stars} />
                                    </div>
                                    <div>
                                        <img src={Qt} />
                                    </div>
                                </div>
                                <p className="text-[#777] mt-10 font-pop text-[12px] sm:text-[14px]">
                                    “Morbi neque nisi, tincidunt nec erat vitae, viverra porttitor lorem. Fusce tempor nunc at luctus blandit. Donec eget fermentum magna.we dedicate financial on services the teams serve all Curabitur ac tortor ante. Sed quis dignissim”
                                </p>
                                <div className="mt-6">
                                    <h1 className="text-[#00173c] font-bold font-pop sm:text-[22px]">Dose Robot</h1>
                                    <p className="text-[#3660d9]">Web Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 py-12">
                            <div className="bg-white px-6 py-6 shadow-[1px_2px_60px_0px_rgba(0,0,0,.01)] rounded-[4px] relative after:w-24">
                                <div className="absolute -top-14 left-0">
                                    <img src={OpImg1} />
                                </div>
                                <div className="flex justify-between mt-6 items-end">
                                    <div>
                                        <img src={Stars} />
                                    </div>
                                    <div>
                                        <img src={Qt} />
                                    </div>
                                </div>
                                <p className="text-[#777] mt-10 font-pop text-[12px] sm:text-[14px]">
                                    “Morbi neque nisi, tincidunt nec erat vitae, viverra porttitor lorem. Fusce tempor nunc at luctus blandit. Donec eget fermentum magna.we dedicate financial on services the teams serve all Curabitur ac tortor ante. Sed quis dignissim”
                                </p>
                                <div className="mt-6">
                                    <h1 className="text-[#00173c] font-bold font-pop sm:text-[22px]">Dose Robot</h1>
                                    <p className="text-[#3660d9]">Web Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 py-12">
                            <div className="bg-white px-6 py-6 shadow-[1px_2px_60px_0px_rgba(0,0,0,.01)] rounded-[4px] relative after:w-24">
                                <div className="absolute -top-14 left-0">
                                    <img src={OpImg1} />
                                </div>
                                <div className="flex justify-between mt-6 items-end">
                                    <div>
                                        <img src={Stars} />
                                    </div>
                                    <div>
                                        <img src={Qt} />
                                    </div>
                                </div>
                                <p className="text-[#777] mt-10 font-pop text-[12px] sm:text-[14px]">
                                    “Morbi neque nisi, tincidunt nec erat vitae, viverra porttitor lorem. Fusce tempor nunc at luctus blandit. Donec eget fermentum magna.we dedicate financial on services the teams serve all Curabitur ac tortor ante. Sed quis dignissim”
                                </p>
                                <div className="mt-6">
                                    <h1 className="text-[#00173c] font-bold font-pop sm:text-[22px]">Dose Robot</h1>
                                    <p className="text-[#3660d9]">Web Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 py-12">
                            <div className="bg-white px-6 py-6 shadow-[1px_2px_60px_0px_rgba(0,0,0,.01)] rounded-[4px] relative after:w-24">
                                <div className="absolute -top-14 left-0">
                                    <img src={OpImg1} />
                                </div>
                                <div className="flex justify-between mt-6 items-end">
                                    <div>
                                        <img src={Stars} />
                                    </div>
                                    <div>
                                        <img src={Qt} />
                                    </div>
                                </div>
                                <p className="text-[#777] mt-10 font-pop text-[12px] sm:text-[14px]">
                                    “Morbi neque nisi, tincidunt nec erat vitae, viverra porttitor lorem. Fusce tempor nunc at luctus blandit. Donec eget fermentum magna.we dedicate financial on services the teams serve all Curabitur ac tortor ante. Sed quis dignissim”
                                </p>
                                <div className="mt-6">
                                    <h1 className="text-[#00173c] font-bold font-pop sm:text-[22px]">Dose Robot</h1>
                                    <p className="text-[#3660d9]">Web Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 py-12">
                            <div className="bg-white px-6 py-6 shadow-[1px_2px_60px_0px_rgba(0,0,0,.01)] rounded-[4px] relative after:w-24">
                                <div className="absolute -top-14 left-0">
                                    <img src={OpImg1} />
                                </div>
                                <div className="flex justify-between mt-6 items-end">
                                    <div>
                                        <img src={Stars} />
                                    </div>
                                    <div>
                                        <img src={Qt} />
                                    </div>
                                </div>
                                <p className="text-[#777] mt-10 font-pop text-[12px] sm:text-[14px]">
                                    “Morbi neque nisi, tincidunt nec erat vitae, viverra porttitor lorem. Fusce tempor nunc at luctus blandit. Donec eget fermentum magna.we dedicate financial on services the teams serve all Curabitur ac tortor ante. Sed quis dignissim”
                                </p>
                                <div className="mt-6">
                                    <h1 className="text-[#00173c] font-bold font-pop sm:text-[22px]">Dose Robot</h1>
                                    <p className="text-[#3660d9]">Web Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 py-12">
                            <div className="bg-white px-6 py-6 shadow-[1px_2px_60px_0px_rgba(0,0,0,.01)] rounded-[4px] relative after:w-24">
                                <div className="absolute -top-14 left-0">
                                    <img src={OpImg1} />
                                </div>
                                <div className="flex justify-between mt-6 items-end">
                                    <div>
                                        <img src={Stars} />
                                    </div>
                                    <div>
                                        <img src={Qt} />
                                    </div>
                                </div>
                                <p className="text-[#777] mt-10 font-pop text-[12px] sm:text-[14px]">
                                    “Morbi neque nisi, tincidunt nec erat vitae, viverra porttitor lorem. Fusce tempor nunc at luctus blandit. Donec eget fermentum magna.we dedicate financial on services the teams serve all Curabitur ac tortor ante. Sed quis dignissim”
                                </p>
                                <div className="mt-6">
                                    <h1 className="text-[#00173c] font-bold font-pop sm:text-[22px]">Dose Robot</h1>
                                    <p className="text-[#3660d9]">Web Developer</p>
                                </div>
                            </div>
                        </div>
                      

                    </Slider>

                </div>
            </div>
        </>
    )
}
export default HomeOpinion