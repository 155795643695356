import React from "react";
import qqImg from "../../assets/img/qq.png";

function HomeAccardion() {
    return (
        <>
            <div className="bg-[#0E264F] flex flex-col md:flex-row">
                <div className=" md:w-1/2 flex justify-center md:justify-end py-10">
                    <div className="max-w-2xl">
                        <div className="mt-16">
                            <h1 className="font-bold font-pop px-4 md:px-0 sm:text-[20px]  lg:text-[40px]  xl:text-[60px] text-white">
                            Frequently Asked Question
                            </h1>
                        </div>
                        <div class="accordion flex flex-col items-center   space-y-3 mt-16  pr-0 max-xl:p-4 md:pr-10">
                            <div class="">
                                <input type="checkbox" name="panel" id="panel-1" class="hidden" />
                                    <label for="panel-1" class="relative block bg-[#f8f8f8] text-[#00173c] font-bold font-pop text-[14px] sm:text-[17px] p-4 height-[60px] Label__BTN">
                                    Vivamus rhoncus ante a ipsum imperdiet ?
                                    </label>
                                    <div class="accordion__content overflow-hidden bg-[white] font-pop text-[#777] text-[13px]">
                                        <p class="accordion__body p-4" id="panel1">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto possimus at a cum saepe molestias modi illo facere ducimus voluptatibus praesentium deleniti fugiat ab error quia sit perspiciatis velit necessitatibus.Lorem ipsum dolor sit amet, consectetur
                                            adipisicing elit. Lorem ipsum dolor sit amet.</p>
                                    </div>
                            </div>
                            <div class="">
                                <input type="checkbox" name="panel" id="panel-2" class="hidden" />
                                    <label for="panel-2" class="relative block bg-[#f8f8f8] text-[#00173c] font-bold font-pop text-[14px] sm:text-[17px] p-4 height-[60px] Label__BTN">
                                    Vivamus rhoncus ante a ipsum imperdiet ?
                                    </label>
                                    <div class="accordion__content overflow-hidden bg-[white] font-pop text-[#777] text-[13px]">
                                        <p class="accordion__body p-4" id="panel2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto possimus at a cum saepe molestias modi illo facere ducimus voluptatibus praesentium deleniti fugiat ab error quia sit perspiciatis velit necessitatibus.Lorem ipsum dolor sit amet, consectetur
                                            adipisicing elit. Lorem ipsum dolor sit amet.</p>
                                    </div>
                            </div>
                            <div class="">
                                <input type="checkbox" name="panel" id="panel-3" class="hidden" />
                                    <label for="panel-3" class="relative block bg-[#f8f8f8] text-[#00173c] font-bold font-pop text-[14px] sm:text-[17px] p-4 height-[60px] Label__BTN">
                                    Vivamus rhoncus ante a ipsum imperdiet ?
                                    </label>
                                    <div class="accordion__content overflow-hidden bg-[white] font-pop text-[#777] text-[13px]">
                                        <p class="accordion__body p-4" id="panel3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto possimus at a cum saepe molestias modi illo facere ducimus voluptatibus praesentium deleniti fugiat ab error quia sit perspiciatis velit necessitatibus.Lorem ipsum dolor sit amet, consectetur
                                            adipisicing elit. Lorem ipsum dolor sit amet.</p>
                                    </div>
                            </div>
                            <div class="">
                                <input type="checkbox" name="panel" id="panel-4" class="hidden" />
                                    <label for="panel-4" class="relative block bg-[#f8f8f8] text-[#00173c] font-bold font-pop text-[14px] sm:text-[17px] p-4 height-[60px] Label__BTN">
                                    Vivamus rhoncus ante a ipsum imperdiet ?
                                    </label>
                                    <div class="accordion__content overflow-hidden  bg-[white] font-pop text-[#777] text-[13px]">
                                        <p class="accordion__body p-4" id="panel4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto possimus at a cum saepe molestias modi illo facere ducimus voluptatibus praesentium deleniti fugiat ab error quia sit perspiciatis velit necessitatibus.Lorem ipsum dolor sit amet, consectetur
                                            adipisicing elit. Lorem ipsum dolor sit amet.</p>
                                    </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="md:w-1/2 p-6 md:p-0">
                    <img src={qqImg} className={'w-full h-full object-cover'} />
                </div>
            </div>
        </>
    )
}
export default HomeAccardion