import React from "react";
import { Link } from "react-router-dom";
import BLIMG1 from '../../assets/img/blog1.jpg';
import BLIMG2 from '../../assets/img/blog2.jpg';
import BLIMG3 from '../../assets/img/blog3.jpg';
import CLIMG from '../../assets/img/calendar.png';
function HomeBlog() {
    return (
        <>

            <div className="max-w-screen-xl mx-auto py-6 px-14 lg:px-24 xl:px-0 mt-10" id="blog">
                <p className="text-[18px] text-center font-bold font-press-start text-[#3660d9]">OUR BLOG</p>
                <h1 className="text-center font-bold font-pop text-[16px]  sm:text-[60px] text-[#00173c]">Latest Blog & News</h1>
            </div>
            <div className="max-w-screen-xl mx-auto py-6  sm:px-14 lg:px-24 xl:px-0 mt-10 flex flex-col lg:flex-row max-lg:space-y-8 lg:space-x-6 pb-24">
                <div className="group max-w-lg max-lg:mx-auto cursor-pointer w-[90%] lg:w-1/3 bg-[white] rounded-[4px]  shadow-[0px_20px_60px_0px_rgba(0,0,0,.08)]">
                    <div className="overflow-hidden relative">
                        <img src={BLIMG1} className={'transition-all duration-500 ease-linear group-hover:scale-110'} />
                        <div className="bg-[#3660d9]  absolute px-[15px] py-[5px] rounded-md text-white font-pop  z-50 bottom-6 left-6 flex items-center justify-center">
                            <img src={CLIMG} className={'h-4 w-4 mr-2'} />  24th March 2021
                        </div>
                    </div>
                    <div className="p-[10px] sm:p-[20px]">
                        <Link className="text-[#00173c] font-bold font-pop text-[16px] md:text-[22px] transition-all duration-300 hover:text-[#3660d9]">
                            Cras accumsan nulla nec lacus ultricies placerat.
                        </Link>
                        <p className="mt-3 font-pop text-[#777] text-[14px]">
                            Curabitur sagittis libero tincidunt tempor finibus. Mauris at dignissim ligula, nec tristique orci.
                        </p>
                        <p className="mt-3">
                            <Link className="mt-3 underline decoration-2 underline-offset-4 font-pop font-bold text-[#3660d9] transition-all duration-500 hover:text-[#00173c]">
                                READ MORE
                            </Link>
                        </p>
                    </div>
                </div>
                <div className="group max-w-lg max-lg:mx-auto cursor-pointer w-[90%] lg:w-1/3 bg-[white] rounded-[4px]  shadow-[0px_20px_60px_0px_rgba(0,0,0,.08)]">
                    <div className="overflow-hidden relative">
                        <img src={BLIMG2} className={'transition-all duration-500 ease-linear group-hover:scale-110'} />
                        <div className="bg-[#3660d9]  absolute px-[15px] py-[5px] rounded-md text-white font-pop  z-50 bottom-6 left-6 flex items-center justify-center">
                            <img src={CLIMG} className={'h-4 w-4 mr-2'} />  24th March 2021
                        </div>
                    </div>
                    <div className="p-[10px] sm:p-[20px]">
                        <Link className="text-[#00173c] font-bold font-pop text-[16px] md:text-[22px] transition-all duration-300 hover:text-[#3660d9]">
                        Dras accumsan nulla nec lacus ultricies placerat.
                        </Link>
                        <p className="mt-3 font-pop text-[#777] text-[14px]">
                            Curabitur sagittis libero tincidunt tempor finibus. Mauris at dignissim ligula, nec tristique orci.
                        </p>
                        <p className="mt-3">
                            <Link className="mt-3 underline decoration-2 underline-offset-4 font-pop font-bold text-[#3660d9] transition-all duration-500 hover:text-[#00173c]">
                                READ MORE
                            </Link>
                        </p>
                    </div>
                </div>
                <div className="group max-w-lg max-lg:mx-auto cursor-pointer w-[90%] lg:w-1/3 bg-[white] rounded-[4px]  shadow-[0px_20px_60px_0px_rgba(0,0,0,.08)]">
                    <div className="overflow-hidden relative">
                        <img src={BLIMG3} className={'transition-all duration-500 ease-linear group-hover:scale-110'} />
                        <div className="bg-[#3660d9]  absolute px-[15px] py-[5px] rounded-md text-white font-pop  z-50 bottom-6 left-6 flex items-center justify-center">
                            <img src={CLIMG} className={'h-4 w-4 mr-2'} />  24th March 2021
                        </div>
                    </div>
                    <div className="p-[10px] sm:p-[20px]">
                        <Link className="text-[#00173c] font-bold font-pop text-[16px] md:text-[22px] transition-all duration-300 hover:text-[#3660d9]">
                        Seas accumsan nulla nec lacus ultricies placerat.
                        </Link>
                        <p className="mt-3 font-pop text-[#777] text-[14px]">
                            Curabitur sagittis libero tincidunt tempor finibus. Mauris at dignissim ligula, nec tristique orci.
                        </p>
                        <p className="mt-3">
                            <Link className="mt-3 underline decoration-2 underline-offset-4 font-pop font-bold text-[#3660d9] transition-all duration-500 hover:text-[#00173c]">
                                READ MORE
                            </Link>
                        </p>
                    </div>
                </div>
               
            </div>

        </>
    )
}

export default HomeBlog